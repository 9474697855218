import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 632.000000 826.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,826.000000) scale(0.100000,-0.100000)">

<path d="M2055 7164 c-200 -22 -458 -80 -619 -140 -268 -100 -540 -296 -691
-499 -16 -22 -32 -42 -36 -45 -22 -19 -100 -151 -150 -253 -80 -166 -125 -311
-149 -482 -6 -44 -15 -107 -21 -141 -14 -96 -11 -446 6 -570 34 -248 91 -440
182 -614 62 -119 78 -144 149 -235 197 -253 411 -404 749 -528 46 -17 190 -56
320 -88 94 -23 122 -36 167 -77 l27 -25 -18 49 c-10 27 -30 75 -45 106 -14 32
-38 104 -52 160 -24 91 -27 123 -28 268 -1 172 0 177 55 360 32 104 35 120 17
102 -7 -7 -22 -12 -34 -12 -12 0 -25 -6 -27 -12 -4 -9 -6 -9 -6 -1 -1 6 -20
21 -44 32 -102 49 -229 247 -262 406 -4 17 -10 39 -15 50 -30 71 -40 477 -16
620 43 248 126 422 276 575 85 87 201 151 335 186 86 22 365 25 460 5 127 -28
287 -91 420 -169 36 -20 164 -113 185 -133 8 -8 36 -31 61 -52 43 -35 50 -37
116 -37 l71 0 6 68 c4 37 11 90 16 117 12 58 19 545 9 545 -4 0 -10 30 -13 68
-14 134 -10 127 -90 166 -169 82 -414 161 -591 190 -55 9 -122 21 -150 27 -50
11 -494 21 -570 13z"/>
<path d="M3236 5398 c-3 -13 -7 -50 -11 -82 -3 -33 -11 -66 -17 -73 -8 -10 -8
-20 0 -38 6 -14 9 -32 6 -40 -4 -8 -1 -15 4 -15 11 0 17 40 27 188 6 77 1 109
-9 60z"/>
<path d="M3431 5304 c0 -44 1 -83 3 -85 2 -2 37 -2 77 -1 l72 4 -29 24 c-16
13 -33 24 -39 24 -18 1 -73 65 -78 90 -2 14 -5 -11 -6 -56z"/>
<path d="M3206 5088 c-7 -73 -26 -340 -26 -372 0 -14 5 -26 10 -26 6 0 10 26
10 58 0 59 16 301 24 360 3 17 1 32 -4 32 -5 0 -11 -24 -14 -52z"/>
<path d="M3171 4570 l1 -65 9 54 c6 36 6 58 -1 65 -7 7 -10 -11 -9 -54z"/>
<path d="M3438 4467 c0 -6 -2 -41 -2 -77 -1 -78 5 -75 -154 -65 l-114 7 5 47
c3 25 2 55 -2 66 -8 19 -8 19 -15 -2 -4 -14 -28 -33 -59 -48 -103 -52 -157
-125 -157 -213 0 -127 58 -192 226 -252 163 -58 445 -112 402 -77 -20 16 -68
108 -68 130 0 9 -7 44 -16 78 -17 67 -14 142 9 229 20 78 78 150 120 150 42 0
-5 19 -63 25 -30 3 -67 8 -82 10 -17 3 -29 0 -30 -8z"/>
<path d="M4781 2964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4793 2860 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M4792 2655 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4782 2585 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4766 2515 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M4750 2449 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"/>
<path d="M4716 2364 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M4695 2309 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4675 2270 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M539 1425 c-119 -43 -181 -126 -195 -258 -16 -154 53 -279 181 -327
70 -27 254 -8 301 31 16 13 20 139 5 139 -5 0 -32 -16 -61 -35 -44 -30 -60
-35 -109 -35 -103 0 -161 66 -162 182 -1 127 57 198 162 198 33 0 59 -4 59
-10 0 -5 6 -10 13 -10 7 0 31 -13 54 -30 22 -16 43 -27 47 -23 3 3 6 35 6 69
0 55 -3 65 -22 78 -66 42 -207 58 -279 31z"/>
<path d="M924 1421 c-2 -2 -4 -134 -4 -293 l0 -288 75 0 74 0 3 123 3 122 98
3 97 3 0 -126 0 -125 80 0 80 0 -2 292 -3 292 -30 0 c-16 -1 -51 -1 -77 -2
l-48 -2 0 -105 0 -106 -97 3 -98 3 -5 104 -5 103 -68 2 c-38 0 -71 -1 -73 -3z"/>
<path d="M2104 1415 c-3 -6 -3 -136 -2 -290 l3 -280 79 -3 78 -3 -2 88 c-3
123 -3 123 23 123 17 0 42 -25 102 -104 l80 -105 88 0 c48 -1 87 2 87 7 0 4
-41 57 -90 117 -49 61 -90 112 -90 115 0 3 13 13 28 24 58 39 90 119 76 192
-9 48 -69 109 -117 118 -61 11 -339 12 -343 1z m260 -109 c38 -16 51 -42 41
-87 -9 -42 -43 -61 -102 -57 l-48 3 4 70 c1 39 5 73 8 78 7 12 61 8 97 -7z"/>
<path d="M2698 1408 c-2 -13 -2 -146 0 -295 l3 -273 200 0 200 0 -3 53 -3 52
-122 3 -123 3 -2 237 -3 237 -72 3 c-68 3 -72 2 -75 -20z"/>
<path d="M3106 1422 c-3 -5 39 -84 94 -176 l99 -167 3 -117 3 -117 73 -3 72
-3 0 118 c0 110 2 121 25 155 14 20 25 39 25 42 0 3 34 61 75 129 41 69 75
130 75 136 0 8 -24 11 -77 9 l-78 -3 -56 -104 c-40 -73 -59 -99 -65 -90 -14
23 -63 112 -81 149 -9 19 -22 38 -27 42 -15 11 -154 10 -160 0z"/>
<path d="M1675 1403 c-4 -10 -36 -103 -71 -208 -35 -104 -68 -199 -73 -210 -5
-11 -18 -48 -29 -83 l-20 -63 76 3 76 3 17 50 17 50 94 3 94 3 18 -56 17 -55
75 0 c40 0 74 4 74 8 0 5 -43 134 -95 287 -52 153 -95 279 -95 281 0 2 -38 4
-84 4 -70 0 -85 -3 -91 -17z m118 -245 c27 -97 29 -93 -30 -96 -41 -2 -53 0
-53 11 0 32 42 147 53 147 7 0 20 -28 30 -62z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
